import { BrowserRouter, Routes, Route } from 'react-router-dom'

// Import pages for react-router-dom
import Home from './pages/home.js'
import Gamescript from './pages/gamescripts.js'
import APIDoc from './pages/apidoc.js'

export default function Routing() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/gamescripts' element={<Gamescript />} />
        <Route path='/apidoc' element={<APIDoc />} />
      </Routes>
    </BrowserRouter>
  );
}