import React from 'react';
import { Link } from 'react-router-dom';
import directory from '../scripts/directory.json';

export default function Table() {
    return (
            <table className="table-striped table-hover table" style={{ width: 259.733, tableLayout: "auto", alignSelf: "center"}}>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Game</th>
                    </tr>
                </thead>
                <tbody>
                    {directory.map(({ gameID, gameName }) => (
                        <tr key={gameID}>
                            <td>{gameID}</td>
                            <td>
                                <Link className="scriptLink" to={`/gamescripts?gameID=${gameID}`}>
                                    {gameName}
                                </Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
    );
}