import React, { useMemo } from 'react';
import '../App.css';

function groupByScene(data) {
    return data.reduce((acc, item) => {
      const scene = (item.scene !== undefined && item.scene !== null) ? item.scene : "Unknown";
      if (!acc[scene]) {
        acc[scene] = [];
      }
      acc[scene].push(item);
      return acc;
    }, {});
  }

function SceneTable({ scene, items, onRowClick }) {
  return (
    <div>
    <h2 className="scene">Scene {scene}</h2>
    <table className="table-striped table-hover table m-top">
      <thead>
        <tr>
          <th>Line</th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {items.map((item) => {
          const { line, portrait, enChar, jpChar, enText, jpText } = item;
          return (
            <tr
              key={line}  // Make sure "line" is unique per row, otherwise use a unique id.
              id={`line-${line}`}
              onClick={() => onRowClick(item)}
            >
              <td>{line}</td>
              <td>
                <img src={portrait} alt="" />
              </td>
              <td>
                <ul>
                  <li>{enChar}</li>
                  <li>{jpChar}</li>
                </ul>
              </td>
              <td>{enText}</td>
              <td>{jpText}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  </div>
  )
}
  
export default function DynamicTable({ script, onFilenameClick }) {
  const groupedData = useMemo(() => groupByScene(script), [script]);

  if (!script) {
    return null;
  }

  const handleRowClick = (item) => {
    if (item?.scriptID && item.filename) {
      onFilenameClick(item.scriptID, item.filename);
    }
  };

  return (
    <div className="dynamtable">
      {Object.entries(groupedData).map(([scene, items]) => (
        <SceneTable
          key={scene}
          scene={scene}
          items={items}
          onRowClick={handleRowClick}
        />
      ))}
    </div>
  );
}